:root {
  --mainColor: #cda45e;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', sans-serif;
}
