.navbar-brand {
  color: var(--mainColor);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 30px;
}
.navbar-brand span {
  color: #000;
}
.navbar-brand small {
  display: block;
  font-size: 13px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 3px;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--mainColor);
}

.main-navbar-light {
  background: transparent !important;
  z-index: 3;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 992px) {
  .main-navbar-light {
    background: #000 !important;
    position: relative;
    top: 0;
    padding: 10px 15px;
  }
  .main-navbar-light .navbar-nav {
    padding-bottom: 10px;
  }
}
.main-navbar-light .navbar-nav > .nav-item > .nav-link {
  font-size: 15px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  opacity: 1 !important;
}
.main-navbar-light .navbar-nav > .nav-item > .nav-link:before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--mainColor);
  transition: all 0.3s ease;
}
.main-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
  color: var(--mainColor);
}
.main-navbar-light .navbar-nav > .nav-item > .nav-link:hover:before {
  width: 100%;
}
@media (max-width: 992px) {
  .main-navbar-light .navbar-nav > .nav-item > .nav-link {
    padding: 0.7rem 0;
    color: rgba(255, 255, 255, 0.7);
  }
  .main-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
    color: #fff;
  }
}
@media (max-width: 767px) {
  .main-navbar-light .navbar-nav > .nav-item > .nav-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.main-navbar-light .navbar-nav > .nav-item.active > a {
  color: var(--mainColor);
}
.main-navbar-light .navbar-toggler {
  border: none;
  color: var(--mainColor) !important;
  cursor: pointer;
  padding-right: 0;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.1em;
}
.main-navbar-light .navbar-toggler:focus {
  outline: none !important;
}
