.hero {
  /* width: 100%;
  height: 100vh;
  background: url('../Images/library.jpg') top center;
  background-size: cover;
  position: relative; */
}
.hero:before {
  content: '';
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.hero .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}
.hero h1 {
  margin: 0;
  font-size: 40px;
  font-weight: bold;
  line-height: 64px;
  color: #fff;
}
.hero .category {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid #fff;
  height: 100%;
  text-align: center;
}

.hero .category i {
  font-size: 32px;
  line-height: 1;
  color: var(--mainColor);
}
.hero .category h3 {
  font-weight: bold;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}
.hero .category h3 p {
  color: #fff;
  transition: ease-in-out 0.3s;
}
.hero .category h3 a:hover {
  border-color: var(--mainColor);
}
.hero .category:hover {
  border-color: var(--mainColor);
}
@media (max-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}
@media (max-width: 1024px) {
  .hero {
    height: auto;
  }
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .hero h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
/* About Section */
.about {
  margin-bottom: 50px;
}
.about .about-desc p {
  font-size: 16px;
  font-weight: lighter;
  color: #000;
  line-height: 1.3;
  text-align: justify;
  text-justify: inter-word;
  /* margin-bottom: 58px; */
  /* padding-right: 50px; */
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about .about-desc p {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about .about-desc p {
    padding-right: 0px;
  }
}
@media (max-width: 575px) {
  .about .about-desc p {
    padding-right: 0px;
  }
}
.about .experience span {
  color: var(--mainColor);
  font-size: 60px;
  font-weight: normal;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about .experience span {
    font-size: 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about .experience span {
    font-size: 45px;
  }
}
@media (max-width: 575px) {
  .about .experience span {
    font-size: 45px;
  }
}

.about .about-right-sec {
  position: relative;
}
@media (max-width: 575px) {
  .about .about-right-sec {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about .about-right-sec {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about .about-right-sec {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about .about-right-sec {
    padding-top: 50px;
  }
}

.about .about-right-sec .about-right-image img {
  width: 100%;
  margin-bottom: 15px;
}

.about .about-right-sec .img-box {
  position: absolute;
  bottom: -7px;
  left: -33px;
  width: 179px;
  height: 180px;
  padding: 31px 10px;
  background: var(--mainColor);
  text-align: center;
}
@media (max-width: 575px) {
  .about .about-right-sec .img-box {
    left: 0;
    width: 128px;
    height: 145px;
    padding: 8px 10px;
  }
}
.about .about-right-sec .img-box:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 31px 34px;
  border-color: transparent transparent var(--mainColor) transparent;
  position: absolute;
  top: -29px;
  content: '';
  right: 0;
}
.about .about-right-sec .img-box span {
  color: #000;
  font-size: 50px;
}
.about .about-right-sec .img-box p {
  color: #000;
  font-size: 15px;
}
.about .about-right-sec p {
  font-size: 22px;
  font-weight: lighter;
  padding-right: 13px;
  line-height: 1.5;
}
@media (max-width: 575px) {
  .about-description {
    margin-bottom: 35px;
  }
}
@media (max-width: 768px) {
  .about .section-title h2 {
    font-size: 20px;
  }
}
