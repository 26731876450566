.contact .info {
  width: 100%;
}
.contact .info i {
  font-size: 20px;
  float: left;
  width: 44px;
  height: 44px;
  background: var(--mainColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}
.contact .info .open-hours,
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}
.contact button[type='submit'] {
  background: #cda45e;
  border: 0;
  padding: 10px 35px;
  color: #000;
  transition: 0.4s;
  border-radius: 50px;
}
.contact button[type='submit']:hover {
  background: #d3af71;
}

@media screen and (max-width: 768px) {
  .contact .section-title h2 {
    font-size: 20px;
  }
}
